import React from 'react';

import { UqAppBarV2, UqFooterV2, UqLayoutV2, UqNavbarV2 } from '@uq-components';
import { HelpshiftPage } from '../../containers/integrations'

import * as integrationStyles from './integration.module.scss';

export default function UqHelpshiftPage() {
  return (
    <UqLayoutV2>
      <UqAppBarV2
        static
        className={integrationStyles.appbar}
      >
        <UqNavbarV2 />
      </UqAppBarV2>

      <div className={integrationStyles.root}>
        <HelpshiftPage />
      </div>

      <UqFooterV2 />
    </UqLayoutV2>
  );
}
